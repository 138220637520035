import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/SMSView.vue";
import ConfirmationPage from "../views/ConfirmationPageView.vue";
import OnlineOptIn from "../views/OnlineOptInView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SMSView",
    component: Home,
  },
  {
    path: '/confirmation-page/:statusId',
    name: 'ConfirmationPageView',
    component: ConfirmationPage,
  },
  {
    path: '/opt-in/',
    name: 'OnlineOptInView',
    component: OnlineOptIn,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
