<template>
  <v-app>
    <Snackbar ref="snackbar"></Snackbar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/layout/Snackbar";
import Bus from "./bus";
export default {
  name: "App",
  components: {
    Snackbar,
  },

  data: () => ({
    //
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      Bus.$on("alert", (type, message) => {
        console.log(message);
        if (type === "success") {
          this.$refs.snackbar.showSuccess(message);
        } else if (type === "warning") {
          this.$refs.snackbar.showWarning(message);
        } else {
          this.$refs.snackbar.showError(message);
        }
      });

      if (this.layout === "default") {
        this.initializeDrawer();
      }
    },
  }
};
</script>
