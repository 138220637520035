<template>
  <v-container class="ConfirmationPage" fill-height fluid>
    <v-row>
      <v-col cols="1" md="0"></v-col>
      <v-col class="d-flex justify-center align-center" style="text-align: center;" cols="10" md="12">
        <v-row class="no-gutters">
          <v-col cols="12" class="d-flex justify-center mb-6">
            <v-img style="max-width: 400px;" src="@/assets/Matcor_logo.png"></v-img>
          </v-col>
          <v-col cols="12" class="mt-6">
            <div v-if="digit === '2'">
              <h1>Thank you for accepting, HR has been informed.</h1>
            </div>
            <div v-else-if="digit === '3'">
              <h1>Message has been declined. Thank you for letting us know.</h1>
            </div>
            <div v-else-if="digit === '4'">
              <h1>You have opted out of all further communications from this system.</h1>
              <h1>If you wish to opt in again, please contact HR.</h1>
            </div>
            <div v-else>
              <h1>Wrong request</h1>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ConfirmationPageView",
  created() {
    this.initialize();
  },
  data: () => ({
    status: null,
    statusIdAccept: 2,
    statusIdDecline: 3,
    statusIdUnsubscribe: 4,
    token: null,
    digit: null,
  }),
  methods: {
    initialize() {
      this.digit = this.$route.params.statusId;
    },
  },
};
</script>
