import Vue from "vue";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API;
// axios.defaults.baseURL = "http://localhost:5002/api";
axios.interceptors.response.use(
  response => response,
  error => {
    console.error("AXIOS ERRROR");
    console.error(error);
    return Promise.reject(error);
  }
);

Vue.$http = axios;
Object.defineProperty(Vue.prototype, "$http", {
  get() {
    return axios;
  },
});
