<template>
  <v-container
    class="pa-0 ma-0 pl-2 pr-2"
    fluid
    fill-height
  >
      
    <v-row class=" d-flex align-center justify-center">
      <v-col style="max-width: 500px" class="text-center">
        <v-row class="no-gutters">
          <v-col cols="12" class="d-flex justify-center pl-2 pr-2">
            <v-img src="@/assets/Matcor_logo.png"></v-img>
          </v-col>
        </v-row>
        <div style="height: 400px;">

          <template v-if="loading">
            <div class="d-flex align-center justify-center" style="height: 100%;">
              <h1 class="mb-6">Loading, please wait...</h1>
            </div>
          </template>
          <template v-else-if="!token">
            <div class="d-flex align-center justify-center" style="height: 100%;">
              <h1 class="mb-6">Opt-in URL is broken. Please contact HR to provide you a fresh link.</h1>
            </div>
          </template>
          <template v-else-if="failedLoading">
            <div class="d-flex align-center justify-center" style="height: 100%;">
              <div>
                <h1 class="mb-6">Failed to load opt-in form.</h1>
                <h2>Check your connection and refresh this page, or contact HR for assistance.</h2><br>
                <v-btn @click="getPlantLocation()" color="primary" elevation="0">Refresh</v-btn>
              </div>
            </div>
          </template>
          <template v-else-if="!optedIn">
            <v-row class="no-gutters">
              <v-col cols="12" class="mt-6">
                <h2>Opt into Corporate Notifications for</h2>
                <h2>{{ this.locationName }}</h2>
              </v-col>
            </v-row>

            <v-row class="mt-15">
              <v-col
                class="mx-6"
                style="
                  position: static;
                  margin-bottom: 0 !important;
                  padding-bottom: 0 !important;
                "
              >
                <v-text-field
                  v-model="phone"
                  label="Mobile Phone Number"
                  outlined
                  required
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="mx-6"
                style="
                  position: static;
                  margin-bottom: 0 !important;
                  padding-bottom: 0 !important;
                "
              >
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  outlined
                  required
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="mx-6"
                style="
                  position: static;
                  margin-bottom: 0 !important;
                  padding-bottom: 0 !important;
                "
              >
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  outlined
                  required
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="12" class="mt-6">
                <v-btn
                  color="primary"
                  style="box-shadow: none"
                  class="mr-6"
                  @click="optInAddUser()"
                  >Opt-IN</v-btn
                >
              </v-col>
            </v-row>
            
          </template>
          <template v-else>
            <div class="d-flex align-center justify-center" style="height: 100%;">
              <div>
                <h1 class="mb-6">Thank you for opting into corporate text messages.</h1>
                <h2>HR has been informed, and you will start receiving notifications once your phone number is verified.</h2>
              </div>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from "@/api/Api";
import Bus from "@/bus";
export default {
  name: "OptInView",
  created() {
    this.initialize();
  },
  data: () => ({
    token: null,
    firstName: null,
    lastName: null,
    phone: null,
    locationName: null,
    locationId: null,
    isVerified: null,
    optedIn: false,
    loading: true,
    failedLoading: false,
  }),
  methods: {
    initialize() {
      this.api = new Api();
      this.getPlantLocation();
    },
    async getPlantLocation() {
      this.loading = true;
      this.failedLoading = false;
      if(!this.token) this.retrieveToken();
      const res = await this.api.getPlantLocation(this.token);
      if (res == 'Network Error: undefined') this.token = null;
      else if(!res || !res.location || !res.locationId || !res.location) this.failedLoading = true;
      else {
        this.locationName = res.location.name;
        this.locationId = res.locationId;
      }
      this.loading = false;
    },
    retrieveToken() {
      const token = this.$route.query.c;
      if (!token) {
        return;
      }
      this.token = token;
    },
    async optInAddUser() {
      if(!this.locationId) await this.getPlantLocation();
      const new_user = {
        locationId: this.locationId,
        phoneNumber: this.phone,
        firstName: this.firstName,
        lastName: this.lastName,
        // isVerified: this.isVerified,
      };
      const result = await this.api.optInAddUser(new_user);
      console.log(result);
      if(result && result.id) this.optedIn = true;
      else if (!result) Bus.$emit("alert","error","Server Error")
      else Bus.$emit("alert","error",result)
    },
  },

  /*async optInAddUser() {
      const res = await this.api.optInAddUser(
        this.token,
        this.firstName,
        this.lastName,
        this.phone
      );
      // Assuming this code snippet is inside an async function
      if (
        this.token === null ||
        this.firstName === null ||
        this.lastName === null ||
        this.phone === null
      ) {
        console.log("At least one field is null. API call cannot be made.");
      } else {
        const res = await this.api.optInAddUser(
          this.token,
          this.firstName,
          this.lastName,
          this.phone
        );
        // Process the API response here
      }
    },*/
};
</script>
