import axios from "axios";

class Api {
  constructor() {}
  async getSMSMessage(token) {
    const res = await axios.get(`/message-content/get?t=${token}`);
    return res.data;
  }

  async statusUpdate(data) {
    let res = null;
    await axios.put(`/message-content`, data)
    .then(response => {
      res = response.data;
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        if (error.response.data && error.response.data.message)
          res = error.response.data.message;
        else if (error.response.data)
          res = "Network Error: " + error.response.data.statusDescription;
        else res = "Network Error: " + error.response.status;
      } else if (error.request) {
        // The request was made but no response was received
        res = "Network Error. Please try again later, or contact HR.";
      } else {
        // Something happened in setting up the request that triggered an Error
        res = "Connection Error";
      }
    });
    return res;
  }

  async getPlantLocation(token) {
    let res = null;
    await axios.get(`/sms-employee/location-config?c=${token}`)
    .then(response => {
      res = response.data;
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        if (error.response.data && error.response.data.message)
          res = error.response.data.message;
        else if (error.response.data)
          res = "Network Error: " + error.response.data.statusDescription;
        else res = "Network Error: " + error.response.status;
      } else if (error.request) {
        // The request was made but no response was received
        res = "Network Error. Please try again later, or contact HR.";
      } else {
        // Something happened in setting up the request that triggered an Error
        res = "Connection Error";
      }
    });
    return res;
  }

  async optInAddUser(data) {
    let res = false;
    await axios
      .post(
        // `api/sms-employee/location-config?c=${location}`,
        "sms-employee/opt-in",
        data
      ).then(response => {
        res = response.data;
      }).catch(error => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.data && error.response.data.message)
            res = error.response.data.message;
          else if (error.response.data)
            res = "Network Error: " + error.response.data.statusDescription;
          else res = "Network Error: " + error.response.status;
        } else if (error.request) {
          // The request was made but no response was received
          res = "Network Error. Please try again later, or contact HR.";
        } else {
          // Something happened in setting up the request that triggered an Error
          res = "Connection Error";
        }
      });
    return res;
  }
  async archive(employeeId) {
    let res = false;
    await axios
      .delete(`${this.endpoint}/${employeeId}`)
      .then(response => {
        res = response.data;
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.data && error.response.data.message)
            res = error.response.data.message;
          else if (error.response.data)
            res = "Network Error: " + error.response.data.statusDescription;
          else res = "Network Error: " + error.response.status;
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          res = "Network Error";
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          res = "Connection Error";
        }
      });
    return res;
  }
}
export default Api;
