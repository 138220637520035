<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    color: "",
    mode: "",
    snackbar: false,
    text: "",
    timeout: 6000,
    x: null,
    y: "top",
  }),
  methods: {
    showSuccess(text) {
      this.text = text;
      this.color = "green";
      this.snackbar = true;
    },
    showWarning(text) {
      this.text = text;
      this.color = "orange";
      this.snackbar = true;
    },
    showError(text) {
      if (text === "") {
        this.text = "Whoops, something went wrong... Unexpected Error.";
      } else {
        this.text = text;
      }
      this.color = "red";
      this.snackbar = true;
    },
  },
};
</script>

<style lang="scss">
@media print {
  body * {
    visibility: hidden;
  }
}
</style>
