<template>
  <v-container
    style="height: 100vh; align-items: center; margin-top: 20vh"
    class="SMSView"
  >
    <v-row v-if="message" align-center style="margin-bottom: 8vh">
      <v-col cols="1" lg="4" md="3" sm="2" />
      <!-- <v-col cols="10" lg="4" md="6" sm="8">
        You have received the invitation from {{ locationName }}.
        <h2>{{ message }}</h2>
      </v-col> -->
      <v-col cols="10" lg="4" md="6" sm="8">
        <v-img src="@/assets/Matcor_logo.png"></v-img>
        <h2 v-html="message"></h2>
        <!-- <div v-html="dialogMessage" style="text-align: top;"></div> -->
      </v-col>
      <v-col cols="1" lg="4" md="3" sm="2" />
    </v-row>

    <v-row v-if="message">
      <v-spacer></v-spacer>
      <v-col cols="6" md="auto">
        <v-btn @click="dialogAccept = true" class="btn-accept">Accept</v-btn>
        <v-dialog v-model="dialogAccept" persistent width="auto">
          <v-card>
            <v-card-title class="text-h5">Accepting confirmation</v-card-title>
            <v-card-text>Please confirm accepting the invitation</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green-darken-1"
                variant="outlined"
                @click="dialogAccept = false"
                style="box-shadow: none"
                >Cancel</v-btn
              >
              <v-btn
                color="green-darken-1"
                variant="outlined"
                style="background-color: green; color: white"
                @click="
                  updateStatus(statusIdAccept);
                  dialog = false;
                "
                >Agree</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6" md="auto">
        <v-btn @click="dialogDecline = true" class="btn-decline">Decline</v-btn>
        <v-dialog v-model="dialogDecline" persistent width="auto">
          <v-card>
            <v-card-title class="text-h5">Declining confirmation </v-card-title>
            <v-card-text>Please confirm declining the invitation</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green-darken-1"
                variant="outlined"
                @click="dialogDecline = false"
                style="box-shadow: none"
                >Cancel</v-btn
              >
              <v-btn
                color="green-darken-1"
                variant="outlined"
                style="background-color: #d40e18; color: white"
                @click="
                  updateStatus(statusIdDecline);
                  dialog = false;
                "
                >Decline</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div v-if="message" class="footer">
      <v-row>
        <v-col cols="4" class="pl-2 pr-0 d-flex justify-start"
          ><span>©2023 Matcor-Matsu</span></v-col
        >
        <v-col cols="3"
          ><span
            ><a @click="updateStatus(statusIdUnsubscribe)">Unsubscribe</a></span
          ></v-col
        >
        <v-col cols="5" class="pl-0 d-flex justify-end pr-8"
          ><span>Powered by BALANCED+</span></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Api from "@/api/Api";
export default {
  name: "SMSView",
  created() {
    this.initialize();
  },
  data: () => ({
    api: null,
    message: "",
    currentStatus: null,
    status: null,
    statusIdAccept: 2,
    statusIdDecline: 3,
    statusIdUnsubscribe: 4,
    token: null,
    dialogAccept: false,
    dialogDecline: false,
  }),
  methods: {
    initialize() {
      this.api = new Api();
      const token = this.$route.query.t;
      if (!token) {
        return;
      }
      this.token = token;
      this.getSMSMessage(token);
    },
    async updateStatus(responseStatusId) {
      // this.dialog = true;
      const submitData = {
        token: this.token,
        responseStatusId: responseStatusId,
      };
      this.result = await this.api.statusUpdate(submitData);
      this.$router.push(`/confirmation-page/${responseStatusId}`);
    },

    async getSMSMessage(token) {
      const res = await this.api.getSMSMessage(token);
      if (res.responseStatusId > 1) {
        this.$router.push(`/confirmation-page/${res.responseStatusId}`);
        return;
      }
      if (res.isAdvanced == false) {
        //if the message is basic, then there is no feedback from the user, so the link was clicked to unsubscribe.
        this.updateStatus(this.statusIdUnsubscribe);
        return;
      }
      this.locationName = res.locationName;
      this.message = res.message;
    },
  },
};
</script>
<style scoped>
.SMSView {
  text-align: center;
  padding-top: 50px;
  color: #000000;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  font-size: 24px;
  margin-bottom: 30px;
}

h2 {
  border: 2px solid #000;
  border-radius: 10px;
  padding: 20px;
  margin-top: 3vh;
}

.btn-decline {
  background-color: #d40e18 !important;
  border: 1px solid #d40e18 !important;
  color: #fff !important;
  margin-right: 3vh !important;
  box-shadow: none;
  font-size: 18px;
  padding: 24px 32px !important;
}
.btn-accept {
  background-color: green !important;
  border: 1px solid green !important;
  color: #fff !important;
  margin-right: 3vh !important;
  box-shadow: none;
  font-size: 18px;
  padding: 24px 32px !important;
  margin-left: 5px !important;
}

.footer {
  position: fixed;
  bottom: 5px;
  color: rgb(0, 0, 0);
  text-align: center; /* Center align the text on XS screens */
  font-size: 12px;
  width: 100%;
  background-color: white;
}
</style>
